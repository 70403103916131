<template>
  <div class="coming-soon">
    <div class="container">
      <img class="logo" src="../assets/logo.png" />
      <br>
      <a href="https://www.facebook.com/plazity">
        <font-awesome-icon :icon="['fab', 'facebook']" size="lg" :style="{ color: 'white' }" />
      </a>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ComingSoon',
  data() {
    return {
      
    }
  }
}
</script>
<style scoped>
.coming-soon {
  background-color: #000;
  text-align: center;  
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  width: 300px;
  margin-bottom: 50px;
}
</style>